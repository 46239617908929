import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Select } from "clutch/src/Select/Select.jsx";
import { mobile } from "clutch/src/Style/style.mjs";
import TextInput from "clutch/src/TextInput/TextInput.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { SelectFaction, SelectSeason } from "@/game-eft/components/Selects.jsx";
import WeaponImage from "@/game-eft/components/WeaponImage.jsx";
import { SearchParamsEnum } from "@/game-eft/constants/constants.mjs";
import { WEAPON_STAT_COLUMNS } from "@/game-eft/constants/statColumns.mjs";
import type { Items, LeaderboardsWeapon } from "@/game-eft/models/graphql.mjs";
import deepFixStrings from "@/game-eft/utils/deep-fix-str-null.mjs";
import useWeaponImages from "@/game-eft/utils/use-weapon-images.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import Container from "@/shared/ContentContainer.jsx";
import DataTable from "@/shared/DataTable.jsx";
import SortOrder from "@/shared/SortOrder.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { formatToFixedNumber, formatToPercent } from "@/util/i18n-helper.mjs";
import { useQuery } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Styled = {
  dataTable: () => css`
    & a {
      color: var(--shade0);
      font-weight: 725;
    }
    & .item-link {
      display: flex;
      align-items: center;
      gap: var(--sp-2);
    }
    & a > div {
      width: var(--sp-18);
      height: var(--sp-7);
      display: inline-table;
    }
    ${mobile} {
      & a.item-link > span {
        max-width: var(--sp-30);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `,
};

const sortOptions = WEAPON_STAT_COLUMNS.map((stat) => ({
  text: stat.display,
  value: stat.key,
}));

const RegExpIsNumber = /^(num|avg)/;

export default function StatsWeapons() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const state = useSnapshot(readState);
  const { getWeaponImage } = useWeaponImages();
  const [weaponName] = useQuery<string>(SearchParamsEnum.WeaponName, "");
  const [searchFilter, setSearchFilter] = useQuery<string>(
    "search",
    weaponName,
  );
  const [sortBy, setSortBy] = useQuery<string>(
    "sort",
    WEAPON_STAT_COLUMNS[0].key,
  );
  const [sortOrder, setSortOrder] = useQuery<"ASC" | "DESC">("order", "DESC");
  const items: Items = readState.eft.items;
  const $leaderboardsWeapons = state.eft.leaderboards.weapons as
    | Array<LeaderboardsWeapon>
    | Error;
  const leaderboardsWeapons: Array<
    LeaderboardsWeapon | LeaderboardsWeaponSanitized
  > = useEvalState($leaderboardsWeapons);
  const weaponStats: Array<LeaderboardsWeaponSanitized> = useMemo(() => {
    const result = [];
    if (!Array.isArray(leaderboardsWeapons)) return result;
    for (const stats of leaderboardsWeapons) {
      const weapon = items[stats.weapon_id];
      if (!weapon?.longName || weapon.longName === weapon.uuid) continue;
      const item = {
        ...stats,
        weapon,
      };
      deepFixStrings(item);
      for (const key in item) {
        if (!RegExpIsNumber.test(key)) continue;
        item[key] = sanitizeNumber(Number(item[key]));
        if (item[key] < 0) item[key] = 0;
      }
      // @ts-ignore
      item.tier = calcWeaponTiersByStat(item as LeaderboardsWeaponSanitized);
      result.push(item);
    }
    return result;
  }, [items, leaderboardsWeapons]);
  const rows = useMemo(() => {
    if (!weaponStats?.length) return null;

    return weaponStats
      .filter((stats) => {
        return (
          stats.weapon.longName
            .toLowerCase()
            .includes(searchFilter.toLowerCase()) &&
          stats.avg_headshot_accuracy < 1
        );
      })
      .sort((a, b) => {
        if (sortBy === "name") {
          return (
            a.weapon.longName.localeCompare(b.weapon.longName) *
            (sortOrder === "ASC" ? -1 : 1)
          );
        }
        const valA = a[sortBy] || 0;
        const valB = b[sortBy] || 0;
        if (valA > valB) {
          return sortOrder === "ASC" ? -1 : 1;
        } else if (valA < valB) {
          return sortOrder === "ASC" ? 1 : -1;
        }
        return 0;
      })
      .map((stats, i) => [
        {
          value: i,
          display: (
            <a className="item-link" href="#">
              <WeaponImage src={getWeaponImage(stats.weapon.longName)} />
              <span>{stats.weapon.longName}</span>
            </a>
          ),
        },
        {
          value: stats.avg_kills_per_raid,
          display: formatToFixedNumber(undefined, stats.avg_kills_per_raid),
        },
        {
          value: stats.avg_accuracy,
          display: formatToPercent(language, stats.avg_accuracy),
        },
        {
          value: stats.avg_headshot_accuracy,
          display: formatToPercent(language, stats.avg_headshot_accuracy),
        },
      ]);
  }, [getWeaponImage, language, searchFilter, sortBy, sortOrder, weaponStats]);

  if (!weaponStats) return null;

  return (
    <Container className="flex column gap-sp-4">
      <div className="flex gap-2">
        <TextInput
          placeholder={t("common:search", "Search")}
          onChange={(e) => setSearchFilter(e.target.value)}
          value={searchFilter}
          Icon={SearchIcon}
        />
        <SelectFaction />
        <SelectSeason />
        <div className="flex justify-end flex-grow gap-sp-2">
          <Select
            options={sortOptions}
            selected={sortBy || ""}
            onChange={(v) => setSortBy(v || undefined)}
          />
          <SortOrder sortOrder={sortOrder} setSortOrder={setSortOrder} />
        </div>
      </div>
      <DataTable
        className={Styled.dataTable()}
        sortable={false}
        indexCol
        indexColTitle="#"
        cols={[
          {
            display: t("eft:weapon", "Weapon"),
            align: "left",
            primary: true,
          },
          ...WEAPON_STAT_COLUMNS.map((stat) => ({
            ...stat,
            display: t(...stat.display),
          })),
        ]}
        rows={rows}
      />
    </Container>
  );
}

type LeaderboardsWeaponSanitized = {
  season_id: string;
  weapon_id: string;
  weapon: {
    longName: string;
    [key: string]: unknown;
  };
  game_mode: string;
  faction: string;
  rank: string;
  num_raids: number;
  avg_kills_per_raid: number;
  avg_accuracy: number;
  avg_headshot_accuracy: number;
  avg_damage_dealt_per_raid: number;
  avg_duration_per_raid: number;
  avg_afflictions_per_raid: number;
  filters: string;
  tier: number;
};

// Utils
function calcWeaponTiersByStat(
  weaponStat: LeaderboardsWeaponSanitized,
): number {
  // These magic numbers are used to create importance to certain stats while creating a balanced tiering system for weapons
  return (
    weaponStat.avg_kills_per_raid +
    sanitizeNumber(weaponStat.avg_accuracy * 4.4) +
    sanitizeNumber(weaponStat.avg_headshot_accuracy * 4.4)
  );
}
