import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { formatDuration } from "@/app/util.mjs";
import MapImage from "@/game-eft/components/MapImage.jsx";
import colors from "@/game-eft/constants/colors.mjs";
import { SearchParamsEnum } from "@/game-eft/constants/constants.mjs";
import type { LeaderboardsMap } from "@/game-eft/models/graphql.mjs";
import deepFixStrings from "@/game-eft/utils/deep-fix-str-null.mjs";
import getMapName from "@/game-eft/utils/get-map-name.mjs";
import useMapImages from "@/game-eft/utils/use-map-images.mjs";
import Container from "@/shared/ContentContainer.jsx";
import DataTable from "@/shared/DataTable.jsx";
import BarChart from "@/shared-fps/BarChart.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { formatToPercent, getLocaleString } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const RegExpIsNumber = /^(num|avg|sum)|rate$/;
const ONE_HOUR = 1000 * 60 * 60;
const ONE_DAY = ONE_HOUR * 24;

export default function LeaderboardMaps() {
  const {
    t,
    i18n: { language: lng },
  } = useTranslation();
  const state = useSnapshot(readState);
  const { getMapImage } = useMapImages();
  const $leaderboardsMaps = state.eft.leaderboards.maps as
    | Array<LeaderboardsMap | LeaderboardsMapSanitized>
    | Error;
  const leaderboardsMaps = useEvalState($leaderboardsMaps);
  const newMap = t(...getMapName(""));
  const mapStats = useMemo(
    () =>
      Object.values(
        (leaderboardsMaps ?? [])
          .map((i) => {
            const result = { ...i };
            deepFixStrings(result);
            for (const key in result) {
              if (!RegExpIsNumber.test(key)) continue;
              result[key] = sanitizeNumber(Number(result[key]));
              if (result[key] < 0) result[key] = 0;
            }
            return result as LeaderboardsMapSanitized;
          })
          .reduce((acc, cur: LeaderboardsMapSanitized) => {
            // Getting the map name as a key to aggregate map stats
            const id = t(...getMapName(cur.game_map_name));
            if (cur.num_raids <= 10 || id === newMap) return acc;
            if (acc[id]) {
              for (const key in cur) {
                const value = cur[key];
                if (typeof value !== "number") continue;
                acc[id][key] = (acc[key] ?? 0) + value;
              }
            } else {
              acc[id] = cur;
            }
            return acc;
          }, {}),
      ) as Array<LeaderboardsMapSanitized>,
    [leaderboardsMaps, newMap, t],
  );
  const rows = useMemo(
    () =>
      mapStats.map((i) =>
        [
          {
            display: (
              <a
                href={`/eft/maps?${SearchParamsEnum.MapName}=${i.game_map_name}`}
                className="flex align-center gap-2"
              >
                <MapImage $url={getMapImage(i.game_map_name)} />
                <span>{t(...getMapName(i.game_map_name))}</span>
              </a>
            ),
            value: i.game_map_name,
          },
          {
            display:
              i.avg_duration_per_raid > ONE_HOUR
                ? t("val:stats.hoursPlayed", "{{ hours }} hours", {
                    hours: formatDuration(i.avg_duration_per_raid, "h:mm:ss"),
                  })
                : t("common:minutes", "{{minutes}} mins", {
                    minutes: formatDuration(i.avg_duration_per_raid, "m:ss"),
                  }),
            value: i.avg_duration_per_raid,
          },
          {
            display: i.avg_kills_per_raid,
            value: i.avg_kills_per_raid,
          },
          {
            display: i.num_kills,
            value: i.num_kills,
          },
          {
            display: i.num_raids,
            value: i.num_raids,
          },
          {
            display:
              i.sum_duration > ONE_DAY
                ? (i.sum_duration / ONE_DAY).toLocaleString(lng, {
                    style: "unit",
                    unit: "day",
                    unitDisplay: "long",
                    maximumFractionDigits: 1,
                  })
                : i.sum_duration > ONE_HOUR
                  ? t("val:stats.hoursPlayed", "{{ hours }} hours", {
                      hours: formatDuration(i.sum_duration, "h:mm:ss"),
                    })
                  : t("common:minutes", "{{minutes}} mins", {
                      minutes: formatDuration(i.sum_duration, "m:ss"),
                    }),
            value: i.sum_duration,
          },
          {
            display: formatToPercent(undefined, i.survival_rate),
            value: i.survival_rate,
          },
        ].map((i) => ({
          ...i,
          display:
            typeof i.display === "number"
              ? getLocaleString(Math.round(i.display))
              : i.display,
        })),
      ),
    [getMapImage, lng, mapStats, t],
  );
  return (
    <Container className="flex column gap-sp-4">
      <BarChart
        title={["eft:survivalRate", "Survival Rate"]}
        data={mapStats.map((i) => ({
          xField: i.game_map_name,
          yField: i.survival_rate * 100,
          avatar: getMapImage(i.game_map_name),
        }))}
        showXAxis
        getBarColor={() => colors.gold.fill}
        getTooltipContent={(i) => (
          <span>
            {t(...getMapName(i.xField))} -{" "}
            {t("eft:srFull", "{{sr}} Survival Rate", {
              sr: formatToPercent(undefined, i.yField / 100),
            })}
          </span>
        )}
        getXValue={getMapImage}
      />
      <DataTable
        indexCol
        indexColTitle="#"
        cols={[
          {
            display: t("common:maps", "Maps"),
            primary: true,
            align: "left",
          },
          {
            display: t("common:stats.durationPerRaid", "Duration / Raid"),
            isStat: true,
            align: "right",
          },
          {
            display: t("eft:killsRaid", "Kills / Raid"),
            isStat: true,
            align: "right",
          },
          {
            display: t("common:stats.kills", "Kills"),
            isStat: true,
            align: "right",
          },
          {
            display: t("eft:stats.raids", "Raids"),
            isStat: true,
            align: "right",
          },
          {
            display: t("battles:eventTable.headers.duration", "Duration"),
            isStat: true,
            align: "right",
          },
          {
            display: t("eft:survivalRate", "Survival Rate"),
            isStat: true,
            align: "right",
          },
        ]}
        rows={rows}
      />
    </Container>
  );
}

type LeaderboardsMapSanitized = {
  season_id: string;
  game_mode: string;
  faction: string;
  num_raids: number;
  survival_rate: number;
  sum_duration: number;
  avg_duration_per_raid: number;
  num_kills: number;
  avg_kills_per_raid: number;
  sum_loot: number;
  avg_loot_per_raid: number;
  filters: string;
  game_map_name: string;
};
