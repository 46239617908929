import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IS_APP } from "@/__main__/constants.mjs";
import LeaderboardMaps from "@/game-eft/components/LeaderboardMaps.jsx";
import StatsWeapons from "@/game-eft/components/StatsWeapons.jsx";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import SharedProfile from "@/shared/Profile.jsx";
import { ProfileLayout } from "@/shared/Profile.style.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

const TABS = {
  WEAPONS: "weapons",
  MAPS: "maps",
};
const metaTabs = {
  [TABS.WEAPONS]: {
    title: ["eft:meta.stats.weapons.title", "EFT Weapon Stats"],
    description: [
      "eft:meta.stats.weapons.description",
      "Dominate Escape from Tarkov with Blitz.gg’s Weapons Stats Page, providing detailed analyses and stats for EFT's extensive arsenal. Make every shot count with insights on damage, recoil, and mod compatibility to fine-tune your loadouts.",
    ],
  },
  [TABS.MAPS]: {
    title: ["eft:meta.stats.maps.title", "EFT Map Stats"],
    description: [
      "eft:meta.stats.maps.description",
      "Master the art of survival in Escape from Tarkov with Blitz.gg’s Maps Stats Page. Strategize your raids with in-depth guides on loot locations, extraction points, and ambush spots, paired with data-driven loadout recommendations for each map.",
    ],
  },
  default: {
    title: ["common:navigation.statistics", "Statistics"],
    description: [
      "eft:meta.stats.description",
      "Explore our Escape From Tarkov Weapon Stats Tracker for a wide range of statistics about all in-game weapons. Sourced from a global player base, our data-driven insights offer a unique perspective on weapon performance.",
    ],
  },
};

function Main() {
  const { t } = useTranslation();
  const {
    parameters: [tab],
  } = useRoute();
  // Tabs
  const baseUrl = `/eft/stats`;
  const tabs = useMemo(
    () => [
      {
        name: t("csgo:navigation.weapons", "Weapons"),
        id: TABS.WEAPONS,
        url: `${baseUrl}/${TABS.WEAPONS}`,
      },
      {
        name: t("csgo:navigation.maps", "Maps"),
        id: TABS.MAPS,
        url: `${baseUrl}/${TABS.MAPS}`,
      },
    ],
    [baseUrl, t],
  );
  const links = useMemo(
    () =>
      tabs.map((tab) => ({
        url: tab.url,
        text: tab.name,
      })),
    [tabs],
  );
  const SelectedTab = useMemo(() => {
    switch (tab) {
      case TABS.WEAPONS:
        return <StatsWeapons />;
      case TABS.MAPS:
        return <LeaderboardMaps />;
    }
  }, [tab]);
  return (
    <ProfileLayout>
      <PageHeader
        imageRound
        title={t(
          metaTabs[tab].title ?? ["common:navigation.statistics", "Statistics"],
        )}
        links={links}
      />
      <Container>
        <SharedProfile>{SelectedTab}</SharedProfile>
      </Container>
    </ProfileLayout>
  );
}

export function meta([tab]) {
  return {
    ...(metaTabs[tab] ?? metaTabs.default),
    subtitle: !IS_APP,
  };
}

export default Main;
